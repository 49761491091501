import SanityBlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { Fragment } from 'react';
import * as React from 'react';

import config from '../../config.json';
import {
  BackgroundTexture,
  HeadingWithIcon,
  LawnBowlIcon,
  Layout,
  SEO,
  SideBySide,
  TrophyIcon,
} from '../components';
import { ContactFormWithMap } from '../components/contact-form-with-map';
import { useGraphQL } from '../hooks';

function BowlsPage({
  data: {
    allSanityBowlsPage: { nodes },
  },
}): React.ReactElement {
  return (
    <Layout>
      <SEO title="Bowls" />
      <UpcomingTournaments nodes={nodes} />
      <BowlsProgram nodes={nodes} />
      <BowlsResults nodes={nodes} />
      <BarefootBowls nodes={nodes} />
      <ContactFormWithMap background="blue" />
    </Layout>
  );
}

function UpcomingTournaments({ nodes }): React.ReactElement {
  const { bowlsUpcomingTournaments } = useGraphQL();
  console.log(nodes);

  return (
    <div
      id="upcoming_tournaments"
      className="relative py-12 scroll-margin-top-24"
    >
      <BackgroundTexture insetTop="full" />
      <div className="relative w-full px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <article className="grid gap-8 lg:grid-cols-5">
          <div className="flex flex-col justify-center lg:col-span-2">
            <div className="flex h-full text-blue-dark">
              <div className="flex flex-col justify-center flex-1 p-4 pt-8">
                <div className="w-full mx-auto max-w-prose">
                  <HeadingWithIcon>
                    <HeadingWithIcon.Heading small>
                      Upcoming
                      <br />
                      Tournaments
                    </HeadingWithIcon.Heading>
                    <HeadingWithIcon.Icon hidden>
                      <LawnBowlIcon />
                    </HeadingWithIcon.Icon>
                  </HeadingWithIcon>
                  <div className="mt-5 prose ">
                    <p>
                      If you would like to enter, please contact our Bowls
                      Co-ordinator Phil Baker at the bowls office on{' '}
                      <a href="tel:(02) 6583 1153" className="inline-block">
                        (02) 6583 1153
                      </a>{' '}
                      or email{' '}
                      <a
                        href="mailto:phil@portcity.com.au"
                        className="inline-block"
                      >
                        phil@portcity.com.au
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <GatsbyImage
                  image={
                    getGatsbyImageData(
                      nodes[1].leftImage.asset,
                      { placeholder: 'dominantColor' },
                      { projectId: 'q45hh4q1', dataset: 'production' }
                    ) as IGatsbyImageData
                  }
                  alt=""
                  className="flex-1 mt-12"
                />
              </div>
            </div>
          </div>
          <ul className="p-12 my-auto border border-gray-400 divide-y divide-gray-400 lg:col-span-3">
            {nodes[1].tournaments.map((t) => (
              <li className="py-4">
                <span className="heading-3">{t.title}</span> {t.details}
              </li>
            ))}
          </ul>
        </article>
      </div>
    </div>
  );
}

function BarefootBowls({ nodes }): React.ReactElement {
  const { barefootbowls1, barefootbowls2 } = useGraphQL();
  return (
    <div id="barefoot_bowls" className="py-12 scroll-margin-top-24">
      <SideBySide
        isReversed={false}
        sideComponent={
          <div className="grid h-full grid-cols-5 gap-4 xs:gap-8">
            {/* <div className="col-span-5 bg-blue-dark">
              <GatsbyImage
                image={
                  getGatsbyImageData(
                    nodes[1].content[0].mainImage.asset,
                    { placeholder: 'dominantColor' },
                    { projectId: 'q45hh4q1', dataset: 'production' }
                  ) as IGatsbyImageData
                }
                alt=""
                className="flex-1 h-full"
              />
            </div> */}
            <div className="col-span-3 bg-blue-dark">
              <GatsbyImage
                image={barefootbowls2.childImageSharp.gatsbyImageData}
                className="flex-1 h-full"
                alt=" "
              />
            </div>
            <div className="col-span-2 bg-blue-dark">
              <GatsbyImage
                image={barefootbowls1.childImageSharp.gatsbyImageData}
                className="flex-1 h-full"
                alt=" "
              />
            </div>
          </div>
        }
      >
        <div className="flex flex-col justify-center flex-1 p-4 pt-8 bg-blue-light">
          <div className="w-full py-8 mx-auto max-w-prose">
            <h2 className="heading-2 heading-accent">Barefoot Bowls</h2>
            <div className="mt-5 prose">
              <p>
                <strong>We love our bowls at Port City!</strong>
                <br />
                <br />
                Barefoot Bowls days can be a fun day for Birthdays, Hens or
                Bucks Days, Corporate Bowls Days, Christmas Parties or simply a
                get together with some friends.
                <br />
                <br />
                $13pp with a maximum number of 8 people per rink.
                <br />
                <br />A range of functions packs and catering options available,
                ask our team for more information. For larger groups, functions
                or to simply book a rink please contact the Club on{' '}
                <a href="tel:(02)65831133">6583 1133.</a>
              </p>
            </div>
          </div>
        </div>
      </SideBySide>
    </div>
  );
}
function BowlsProgram({ nodes }): React.ReactElement {
  const { bowlsProgram1, bowlsProgram2, bowlsProgram3 } = useGraphQL();
  return (
    <div id="bowls_program" className="py-12 scroll-margin-top-24">
      <SideBySide
        isReversed={false}
        sideComponent={
          <div className="grid h-full grid-cols-5 gap-4 xs:gap-8">
            <div className="col-span-5 bg-blue-dark">
              <GatsbyImage
                image={
                  getGatsbyImageData(
                    nodes[1].content[0].mainImage.asset,
                    { placeholder: 'dominantColor' },
                    { projectId: 'q45hh4q1', dataset: 'production' }
                  ) as IGatsbyImageData
                }
                alt=""
                className="flex-1 h-full"
              />
            </div>
            <div className="col-span-3 bg-blue-dark">
              <GatsbyImage
                image={
                  getGatsbyImageData(
                    nodes[1].content[0].secondaryImages[0].asset,
                    { width: 1280, placeholder: 'dominantColor' },
                    { projectId: 'q45hh4q1', dataset: 'production' }
                  ) as IGatsbyImageData
                }
                alt=""
                className="flex-1 h-full"
              />
            </div>
            <div className="col-span-2 bg-blue-dark">
              <GatsbyImage
                image={
                  getGatsbyImageData(
                    nodes[1].content[0].secondaryImages[1].asset,
                    { width: 1280, placeholder: 'dominantColor' },
                    { projectId: 'q45hh4q1', dataset: 'production' }
                  ) as IGatsbyImageData
                }
                alt=""
                className="flex-1 h-full"
              />
            </div>
          </div>
        }
      >
        <div className="flex flex-col justify-center flex-1 p-4 pt-8 bg-blue-light">
          <div className="w-full py-8 mx-auto max-w-prose">
            <h2 className="heading-2 heading-accent">
              {nodes[1].content[0].heading.map((line, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                  {line}
                  {index !== nodes[1].content[0].heading.length - 1 && <br />}
                </Fragment>
              ))}
            </h2>
            {/* <div className="mt-5 prose">
              <p>
                <span className="heading-3">Monday</span>
                <br />
                Greens closed (special events only)
              </p>
              <p>
                <span className="heading-3">Tuesday</span>
                <br />
                Tuesday Ladies Social Day from 9:30am
              </p>
              <p>
                <span className="heading-3">Wednesday</span>
                <br />
                Open Pairs from 9:30am
                <br />
                Men&lsquo;s Triples Social Day from 1:00pm
              </p>
              <p>
                <span className="heading-3">Thursday</span>
                <br />
                Open Pairs from 1:00pm
              </p>
              <p>
                <span className="heading-3">Saturday</span>
                <br />
                Ladies Social Triples &amp; Pairs from 9:30am
              </p>
              <p>
                <span className="heading-3">Sunday</span>
                <br />
                Travelling Bowlers Men&lsquo;s Pairs from 10:00am
                <br />
                Open Triples from 1:00pm
              </p>
              <p>
                If you wish to have a roll up please contact our Bowls
                Co-ordinator Phil Baker at the Bowls Office so we can have a
                rink set up for you on arrival. Green fees of $10 per head are
                to be paid at reception.
              </p>
              <p>
                Coaching available on request, please contact Phil for details.
              </p>
              <p>
                <span className="font-semibold text-blue-dark">
                  Bowls Co-Ordinator:{' '}
                </span>
                Phil Baker
                <br />
                <span className="font-semibold text-blue-dark">Phone: </span>
                <a href={`tel:${config.bowlsPhone}`}>{config.bowlsPhone}</a>
                <br />
                <span className="font-semibold text-blue-dark">Email: </span>
                <a href={`mailto:${config.bowlsEmail}`}>{config.bowlsEmail}</a>
              </p>
            </div> */}
            <SanityBlockContent
              blocks={nodes[1].content[0]._rawCopy}
              renderContainerOnSingleChild
              className="mt-5 prose"
            />
          </div>
        </div>
      </SideBySide>
    </div>
  );
}

function BowlsResults({ nodes }): React.ReactElement {
  return (
    <div id="bowls_results" className="relative py-12 scroll-margin-top-24">
      <BackgroundTexture insetBottom="full" overflowBottom />
      <div className="relative">
        <SideBySide
          isReversed
          sideComponent={
            nodes[1].content[1].mainImage?.asset ? (
              <GatsbyImage
                image={
                  getGatsbyImageData(
                    nodes[1].content[1].mainImage.asset,
                    { width: 1280, placeholder: 'dominantColor' },
                    { projectId: 'q45hh4q1', dataset: 'production' }
                  ) as IGatsbyImageData
                }
                alt=""
                className="flex-1 h-full"
              />
            ) : null
          }
        >
          <div className="flex h-full text-blue-dark">
            <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose ">
              <HeadingWithIcon>
                <HeadingWithIcon.Heading>
                  {nodes[1].content[1].heading.map((line, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                      {line}
                      {index !== nodes[1].content[1].heading.length - 1 && (
                        <br />
                      )}
                    </Fragment>
                  ))}
                </HeadingWithIcon.Heading>
                <HeadingWithIcon.Icon hidden>
                  <TrophyIcon />
                </HeadingWithIcon.Icon>
              </HeadingWithIcon>
              <SanityBlockContent
                blocks={nodes[1].content[1]._rawCopy}
                renderContainerOnSingleChild
                className="mt-8 prose max-w-none"
              />
            </div>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

export const graphqlQuery = graphql`
  query MyQuery {
    allSanityBowlsPage {
      nodes {
        tournaments {
          id: _key
          details
          title
        }
        leftImage {
          asset {
            _id
          }
        }
        title
        id
        description
        content {
          mainImage {
            asset {
              _id
            }
          }
          secondaryImages {
            asset {
              _id
            }
          }
          _rawCtas
          _rawCopy
          heading
          isLightBlue
          isReversed
          subheading
        }
      }
    }
  }
`;

export { BowlsPage as default };
